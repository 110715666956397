import Vue from 'vue'
import router from './router'
import store from './store'
// import VueMaterial from 'vue-material'
import Loading from 'vue-loading-overlay'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate"
import de from 'vee-validate/dist/locale/de.json'
import * as rules from "vee-validate/dist/rules"

import 'vue-loading-overlay/dist/vue-loading.css'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import JsonCSV from 'vue-json-csv'
import FlashMessage from '@smartweb/vue-flash-message'

import ZwInput from '@/components/form/ZwInput'
import ZwSelect from '@/components/form/ZwSelect'
import ZwRadioGroup from '@/components/form/ZwRadioGroup'
import ZwDate from '@/components/form/ZwDate'
import ZwSwitch from '@/components/form/ZwSwitch'
import ZwAutocomplete from './components/form/ZwAutocomplete'
import ZwTime from '@/components/form/ZwTime'
import ZwTime2 from '@/components/form/ZwTime2'
import ZwTimeModal from '@/components/form/ZwTimeModal'
import ZwTimeField from '@/components/worklog/ZwTimeField'

import EmployeeForm from '@/components/EmployeeForm'
import CompanyForm from '@/components/CompanyForm'
import ConstructionZoneForm from '@/components/ConstructionZoneForm'
import DepartmentForm from '@/components/DepartmentForm'
import Confirm from '@/components/Confirm'
import Roles from "./views/Roles"
import RoleForm from "./components/RoleForm"
import WorklogForm from "./components/WorklogForm"
import OptionForm from "./components/OptionForm"

import moment from "moment"
import {extendMoment} from 'moment-range'
import {createProvider} from './vue-apollo'
import './registerServiceWorker'

const rangemoment = extendMoment(moment);

axios.defaults.baseURL = 'https://app-api.bug-gruppe.de/api'
// axios.defaults.baseURL = 'https://bug-be.test.uva.de/api'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api'
// axios.defaults.baseURL = 'https://uva.zw-server.de/api'
// axios.defaults.headers = {
//     Authorization: `Bearer ${localStorage.getItem('token')}`,
//     'Access-Control-Allow-Origin': '*',
//     'Accept': 'application/json'
// }


Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

const isBetween = (value, data) => {
    let found = false
    let index = data[1];
    data[0].forEach((subItem, i) => {
        if (i != index) {
            let minTime = subItem.work_start
            let maxTime = subItem.work_end

            if (subItem.pause_start && subItem.work_start > subItem.pause_start) {
                minTime = subItem.pause_start
            }
            if (subItem.pause_end && subItem.work_end < subItem.pause_end) {
                maxTime = subItem.pause_end
            }

            if (value > minTime && value < maxTime) {
                found = true
            }
        }
    })

    return !found
}

extend('timeRanges', isBetween);


localize('de', de);

// Vue.use(VueMaterial)
Vue.use(Loading)
Vue.use(VueAxios, axios)
Vue.use(FlashMessage)

Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)
Vue.component('downloadCsv', JsonCSV)
Vue.component('loading', Loading)
Vue.component('flashMessage', FlashMessage)

Vue.component('zw-input', ZwInput)
Vue.component('zw-select', ZwSelect)
Vue.component('zw-radio-group', ZwRadioGroup)
Vue.component('zw-date', ZwDate)
Vue.component('zw-switch', ZwSwitch)
Vue.component('zw-autocomplete', ZwAutocomplete)
Vue.component('zw-time', ZwTime)
Vue.component('zw-time2', ZwTime2)
Vue.component('zw-time-modal', ZwTimeModal)
Vue.component('zw-time-field', ZwTimeField)

Vue.component('employee-form', EmployeeForm)
Vue.component('company-form', CompanyForm)
Vue.component('construction-zone-form', ConstructionZoneForm)
Vue.component('department-form', DepartmentForm)
Vue.component('role-form', RoleForm)
Vue.component('option-form', OptionForm)
Vue.component('worklog-form', WorklogForm)

Vue.component('roles', Roles)

Vue.component('confirm', Confirm)

Vue.config.productionTip = false

Vue.filter('dateFormat', function (value, format = 'ddd, DD') {
    return moment(value).locale('de').format(format)
})

Vue.filter('toHours', function (value) {
    return value ? (value / 60).toFixed(2) : value;
})

new Vue({
    router,
    store,
    vuetify,
    apolloProvider: createProvider(),
    render: h => h(App)
}).$mount('#app')
