import Vue from "vue";
import VueApollo from "vue-apollo";
import { createApolloClient, restartWebsockets } from "vue-cli-plugin-apollo/graphql-client";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || "http://127.0.0.1:8001/graphql";

// Config
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    wsEndpoint: null,
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,
    // $query: {
    //     fetchPolicy: 'network-only',
    // },

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    // link: myLink

    // Override default cache
    // cache: myCache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...

    // Additional ApolloClient options
    // apollo: {}

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
};

// You can use `https` for secure connection (recommended in production)
const clientSecuredOptions = {
    httpEndpoint: process.env.NODE_ENV === "production" ? "https://app-api.bug-gruppe.de/graphql" : "https://app-api.bug-gruppe.de/graphql",
    // httpEndpoint: 'https://bug-be.test.uva.de/graphql',
    // httpEndpoint: 'http://127.0.0.1:8000/graphql',
    // httpEndpoint: 'https://bug-timetracking-be.ddev.site/graphql',
    // httpEndpoint: 'https://uva.zw-server.de/graphql',
};

const clientUnsecuredOptions = {
    httpEndpoint: process.env.NODE_ENV === "production" ? "https://app-api.bug-gruppe.de/graphql/unsecured" : "https://app-api.bug-gruppe.de/graphql/unsecured",
    // httpEndpoint: 'https://bug-be.test.uva.de/graphql/unsecured',
    // httpEndpoint: 'http://127.0.0.1:8000/graphql/unsecured',
    // httpEndpoint: 'https://bug-timetracking-be.ddev.site/graphql/unsecured',
    // httpEndpoint: 'https://uva.zw-server.de/graphql/unsecured',
};

// Call this in the Vue app file
export function createProvider(options = {}) {
    const createSecured = createApolloClient({
        ...defaultOptions,
        ...clientSecuredOptions,
    });

    const createUnsecured = createApolloClient({
        ...defaultOptions,
        ...clientUnsecuredOptions,
    });

    const secured = createSecured.apolloClient;
    const unsecured = createUnsecured.apolloClient;

    // Create vue apollo provider
    const apolloProvider = new VueApollo({
        clients: {
            secured,
            unsecured,
        },
        defaultClient: secured,
    });

    return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
    if (typeof localStorage !== "undefined" && token) {
        localStorage.setItem(AUTH_TOKEN, token);
    }
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
    try {
        await apolloClient.resetStore();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log("%cError on cache reset (login)", "color: orange;", e.message);
    }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
    if (typeof localStorage !== "undefined") {
        localStorage.removeItem(AUTH_TOKEN);
    }
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
    try {
        await apolloClient.resetStore();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log("%cError on cache reset (logout)", "color: orange;", e.message);
    }
}
